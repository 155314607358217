var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "mc-product-banner",
        staticStyle: {
          background:
            "url('build/images/banners/product-banner-01.jpg') no-repeat center center",
        },
      },
      [
        _c("h3", { staticClass: "mc-product-banner__title" }, [
          _vm._v("Osjećaj "),
          _c("br"),
          _vm._v(" sigurnosti "),
          _c("br"),
          _vm._v(" u vlastitom "),
          _c("br"),
          _vm._v(" domu."),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "mc-product mc-product--w25" }, [
          _c(
            "div",
            { staticClass: "mc-product__info mc-product__info--discount" },
            [_vm._v("-30%")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mc-product__header" }, [
            _c("img", {
              staticClass: "mc-product__image",
              attrs: {
                src: "build/images/products/200x200/product-01.png",
                alt: "Product title",
                loading: "lazy",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mc-product__body" }, [
            _c("h4", { staticClass: "mc-product__brand" }, [_vm._v("Dahua")]),
            _vm._v(" "),
            _c("h3", { staticClass: "mc-product__title" }, [
              _vm._v("Video nadzor NVR-1B04-4P"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mc-product__price-wrapper" }, [
              _c("span", { staticClass: "mc-product__price" }, [
                _vm._v("5.505,00"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "mc-product__currency" }, [
                _vm._v("eur"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("a", { staticClass: "mc-product__link", attrs: { href: "#" } }),
          _vm._v(" "),
          _c("div", { staticClass: "mc-product__footer" }, [
            _c(
              "button",
              { staticClass: "mc-add-to-cart mc-add-to-cart--red" },
              [_vm._v("Dodaj u košaricu")]
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }