var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isSet
    ? _c("div", { staticClass: "cookies" }, [
        _c("div", { staticClass: "cookies__inner" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "cookies__button-wrapper" }, [
            _c(
              "button",
              {
                staticClass: "mc-button mc-button--cookie",
                on: { click: _vm.close },
              },
              [_vm._v("Prihvati")]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cookies__text" }, [
      _c(
        "a",
        {
          staticClass: "cookies__text-link",
          attrs: { href: "/pravila-privatnosti#cookies" },
        },
        [_vm._v("Kolačići")]
      ),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "nam pomažu u pružanju naših usluga. Korištenjem naših usluga pristajete na upotrebu kolačića."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }