<template>
  <main class="products">
    <div class="products__header">
      <ProductsQuickSearch :user-username="userUsername"/>
    </div>
    <div class="products__body">
      <div class="products__aside">
        <div class="products__aside-top">
          <div class="products__aside-top-l">
            <span><svg width="23" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.571 2.286H0V0h20.571v2.286zm-8.217 9.143H8v2.285h3.531a6.84 6.84 0 01.823-2.285zm4.789-5.715H3.429V8h13.714V5.714zm0 4.572v3.428h-3.429V16h3.429v3.429h2.286V16h3.428v-2.286H19.43v-3.428h-2.286z" fill="#000"/></svg></span>
            <span>Filtriraj</span>
          </div>
          <div @click="toggleFilters" class="products__aside-top-r">Close</div>
        </div>
        <transition-group name="fade" tag="div">
          <div
            v-for="category in filteredCategories"
            :key="category.slug"
            class="products__aside-group">
            <div class="category-filter">
              <div class="category-filter__header">
                <h3 class="category-filter__title">{{ category.title }}</h3>
              </div>
              <div class="category-filter__body">
                <a
                  v-for="item in category.children"
                  :href="'/proizvodi/' + category.slug + '/' + item.slug"
                  class="category-filter__link"
                >
                  {{ item.title }}
                </a>
              </div>
            </div>
          </div>
        </transition-group>
        <transition name="fade">
          <div v-if="!isCategoriesLoaded" class="products__aside-group products__aside-group--placeholder">
            <div class="category-filter category-filter--placeholder">
              <div class="category-filter__header category-filter__header--placeholder">
                <h3 class="category-filter__title category-filter__title--placeholder"></h3>
              </div>
              <div class="category-filter__body category-filter__body--placeholder">
                <a href="#" class="category-filter__link category-filter__link--placeholder-1"></a>
                <a href="#" class="category-filter__link category-filter__link--placeholder-3"></a>
                <a href="#" class="category-filter__link category-filter__link--placeholder-2"></a>
                <a href="#" class="category-filter__link category-filter__link--placeholder-5"></a>
                <a href="#" class="category-filter__link category-filter__link--placeholder-4"></a>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="products__main">
        <div class="products__top">
          <h1 class="products__title">Proizvodi</h1>
          <div class="products__top-actions">
            <div class="products__filter-toggle" @click="toggleFilters">
              <div class="products__filter-toggle-inner">
                <span class="products__filter-toggle-title">Filtriraj</span>
                <span class="products__filter-toggle-icon">
                  <svg width="23" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.571 2.286H0V0h20.571v2.286zm-8.217 9.143H8v2.285h3.531a6.84 6.84 0 01.823-2.285zm4.789-5.715H3.429V8h13.714V5.714zm0 4.572v3.428h-3.429V16h3.429v3.429h2.286V16h3.428v-2.286H19.43v-3.428h-2.286z" fill="#000"/></svg>
                </span>
              </div>
            </div>
            <div v-click-outside="closeSort" class="products__sort-toggle">
              <div @click="toggleSort" class="products__sort-toggle-inner">
                <span class="products__sort-toggle-title">Sortiraj</span>
                <span class="products__sort-toggle-icon" :class="'n' ? activePriceSort : 'v'">
                  <svg width="22" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.6 19l-4.4-4.222h3.3V4.222h-3.3L17.6 0 22 4.222h-3.3v10.556H22L17.6 19zM0 16.889v-2.111h11v2.11H0zm0-6.333V8.444h7.7v2.112H0zm0-6.334v-2.11h4.4v2.11H0z" fill="#000"/></svg>
                </span>
              </div>
              <div v-if="isSortVisible" class="products__sort-toggle-dropdown">
                <ul>
                  <li @click="onSortItemClick('n')"><strong>Cijena:</strong> manja prema većoj</li>
                  <li @click="onSortItemClick('v')"><strong>Cijena:</strong> veća prema manjoj</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="products__items">
          <ProductItem
            v-if="product.hasOwnProperty('category')"
            v-for="product in filteredProducts"
            :key="product.id"
            :product="product"
            :user-username="userUsername"
          />
        </div>
      </div>
    </div>
    <div class="products__footer"></div>
  </main>
</template>

<script>
import ProductItem from '../components/product-item/ProductItem'
import ApiService from "../services/api.service"
import ProductsFilter from "../components/products-filter/ProductsFilter";
import ProductsQuickSearch from "../components/products-quick-search/ProductsQuickSearch";
import ClickOutside from "vue-click-outside";

export default {
  name: 'ProductsCategory',
  props: {
    userUsername: {
      type: String,
      required: true
    },
    category: {
      type: String
    },
    subCategory: {
      type: String
    }
  },
  components: {
    ProductItem,
    ProductsFilter,
    ProductsQuickSearch
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      products: [],
      filteredProducts: [],
      isProductsLoaded: false,

      categories: [],
      filteredCategories: [],
      isCategoriesLoaded: false,

      itemsPerPage: 18,
      currentPage: 1,
      isLoadMoreButtonVisible: true,

      currentQuery: '?isPublish=true',

      isSortVisible: false,
      activePriceSort: 'n',

      count: 0
    }
  },
  mounted() {
    this.getProducts('/api/v1/products', `?properties[]=slug&properties[]=name&properties[]=price&properties[]=specialPrice&properties[]=shortDescription&properties[]=category&properties[]=featuredImage&isPublish=true&category.root.slug=${this.category}&order[showInCategory]=desc&itemsPerPage=30`).then(({ data }) => {
      this.filteredProducts = [...data['hydra:member']]
      this.isProductsLoaded = true

      this.getProductsCategories('/api/v1/product_categories', `?root.slug=${this.category}&order[createdAt]=desc&order[position]=asc&itemsPerPage=90`).then(({ data }) => {
        this.categories = [...data['hydra:member']]
        this.categories.forEach(category => {
          if (category['@id'] === category.root) {
            this.filteredCategories.push({
              title: category.title,
              slug: category.slug,
              children: []
            })
          }
        })
        this.categories.forEach(category => {
          if (category['@id'] !== category.root) {
            this.filteredCategories.forEach((filteredCategory, key) => {
              if (category.parent.slug === filteredCategory.slug) {
                this.filteredCategories[key].children.push(category)
              }
            })
          }
        })

        this.isCategoriesLoaded = true
      })
    })
  },
  methods: {
    async getProducts(endpoint, filter) {
      try {
        return await ApiService.get(endpoint, filter)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getProductsCategories(endpoint, filter) {
      try {
        return await ApiService.get(endpoint, filter)
      } catch (e) {
        throw new Error(e)
      }
    },
    onLoadMoreProductsClick() {
      this.loadMoreProducts()
    },
    loadMoreProducts() {
      this.isProductsLoaded = false
      this.currentPage ++

      // if (this.category && this.subCategory)
      // if (this.subCategory === '' && this.category)
      // if (this.category === '' && this.subCategory === '')

      if (this.category && this.subCategory) {
        this.getProducts('/api/v1/products', `?isPublish=true&category.slug=${this.subCategory}&itemsPerPage=${this.itemsPerPage}&page=${this.currentPage}`).then(({ data }) => {
          this.filteredProducts.push(...data['hydra:member'])
          this.isProductsLoaded = true

          if (data['hydra:member'].length < this.itemsPerPage) this.isLoadMoreButtonVisible = false
        })
      }
      if (this.subCategory === '' && this.category) {
        this.getProducts('/api/v1/products', `?isPublish=true&category.root.slug=${this.category}&itemsPerPage=${this.itemsPerPage}&page=${this.currentPage}`).then(({ data }) => {
          this.filteredProducts.push(...data['hydra:member'])
          this.isProductsLoaded = true

          if (data['hydra:member'].length < this.itemsPerPage) this.isLoadMoreButtonVisible = false
        })
      }
      if (this.category === '' && this.subCategory === '') {
        this.getProducts('/api/v1/products', `?isPublish=true&itemsPerPage=${this.itemsPerPage}&page=${this.currentPage}`).then(({ data }) => {
          this.filteredProducts.push(...data['hydra:member'])
          this.isProductsLoaded = true

          if (data['hydra:member'].length < this.itemsPerPage) this.isLoadMoreButtonVisible = false
        })
      }
    },
    toggleFilters() {
      const aside = document.querySelector('.products__aside')
      aside.classList.toggle('products__aside-show')
    },
    toggleSort() {
      this.isSortVisible = !this.isSortVisible
    },
    closeSort() {
      this.isSortVisible = false
    },
    onSortItemClick(payload) {
      if (payload === 'v') this.activePriceSort = 'v'
      else this.activePriceSort = 'n'

      if (this.activePriceSort === 'v') this.filteredProducts = this.filteredProducts.sort((a, b) => b.price - a.price)
      else this.filteredProducts = this.filteredProducts.sort((a, b) => a.price - b.price)

      this.isSortVisible = false
    }
  }
}
</script>
