var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "products" }, [
    _c(
      "div",
      { staticClass: "products__header" },
      [
        _c("ProductsQuickSearch", {
          attrs: { "user-username": _vm.userUsername },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "products__body" }, [
      _c(
        "div",
        { staticClass: "products__aside" },
        [
          _c("div", { staticClass: "products__aside-top" }, [
            _c("div", { staticClass: "products__aside-top-l" }, [
              _c("span", [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "23",
                      height: "20",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M20.571 2.286H0V0h20.571v2.286zm-8.217 9.143H8v2.285h3.531a6.84 6.84 0 01.823-2.285zm4.789-5.715H3.429V8h13.714V5.714zm0 4.572v3.428h-3.429V16h3.429v3.429h2.286V16h3.428v-2.286H19.43v-3.428h-2.286z",
                        fill: "#000",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v("Filtriraj")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "products__aside-top-r",
                on: { click: _vm.toggleFilters },
              },
              [_vm._v("Close")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "transition-group",
            { attrs: { name: "fade", tag: "div" } },
            _vm._l(_vm.filteredCategories, function (category) {
              return _c(
                "div",
                { key: category.slug, staticClass: "products__aside-group" },
                [
                  _c("div", { staticClass: "category-filter" }, [
                    _c("div", { staticClass: "category-filter__header" }, [
                      _c("h3", { staticClass: "category-filter__title" }, [
                        _c(
                          "a",
                          { attrs: { href: "/proizvodi/" + category.slug } },
                          [_vm._v(_vm._s(category.title))]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "category-filter__body" },
                      _vm._l(category.children, function (item) {
                        return !item.isArchived
                          ? _c(
                              "a",
                              {
                                staticClass: "category-filter__link",
                                attrs: {
                                  href:
                                    "/proizvodi/" +
                                    category.slug +
                                    "/" +
                                    item.slug,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.title) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e()
                      }),
                      0
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            !_vm.isCategoriesLoaded
              ? _c(
                  "div",
                  {
                    staticClass:
                      "products__aside-group products__aside-group--placeholder",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "category-filter category-filter--placeholder",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__header category-filter__header--placeholder",
                          },
                          [
                            _c("h3", {
                              staticClass:
                                "category-filter__title category-filter__title--placeholder",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__body category-filter__body--placeholder",
                          },
                          [
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-1",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-3",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-2",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-5",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-4",
                              attrs: { href: "#" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            !_vm.isCategoriesLoaded
              ? _c(
                  "div",
                  {
                    staticClass:
                      "products__aside-group products__aside-group--placeholder",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "category-filter category-filter--placeholder",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__header category-filter__header--placeholder",
                          },
                          [
                            _c("h3", {
                              staticClass:
                                "category-filter__title category-filter__title--placeholder",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__body category-filter__body--placeholder",
                          },
                          [
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-1",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-3",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-2",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-5",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-4",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-2",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-5",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-4",
                              attrs: { href: "#" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            !_vm.isCategoriesLoaded
              ? _c(
                  "div",
                  {
                    staticClass:
                      "products__aside-group products__aside-group--placeholder",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "category-filter category-filter--placeholder",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__header category-filter__header--placeholder",
                          },
                          [
                            _c("h3", {
                              staticClass:
                                "category-filter__title category-filter__title--placeholder",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__body category-filter__body--placeholder",
                          },
                          [
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-2",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-5",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-4",
                              attrs: { href: "#" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            !_vm.isCategoriesLoaded
              ? _c(
                  "div",
                  {
                    staticClass:
                      "products__aside-group products__aside-group--placeholder",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "category-filter category-filter--placeholder",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__header category-filter__header--placeholder",
                          },
                          [
                            _c("h3", {
                              staticClass:
                                "category-filter__title category-filter__title--placeholder",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__body category-filter__body--placeholder",
                          },
                          [
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-1",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-3",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-2",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-5",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-4",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-1",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-3",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-5",
                              attrs: { href: "#" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            !_vm.isCategoriesLoaded
              ? _c(
                  "div",
                  {
                    staticClass:
                      "products__aside-group products__aside-group--placeholder",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "category-filter category-filter--placeholder",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__header category-filter__header--placeholder",
                          },
                          [
                            _c("h3", {
                              staticClass:
                                "category-filter__title category-filter__title--placeholder",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__body category-filter__body--placeholder",
                          },
                          [
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-1",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-3",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-4",
                              attrs: { href: "#" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            !_vm.isCategoriesLoaded
              ? _c(
                  "div",
                  {
                    staticClass:
                      "products__aside-group products__aside-group--placeholder",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "category-filter category-filter--placeholder",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__header category-filter__header--placeholder",
                          },
                          [
                            _c("h3", {
                              staticClass:
                                "category-filter__title category-filter__title--placeholder",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "category-filter__body category-filter__body--placeholder",
                          },
                          [
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-1",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-2",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-5",
                              attrs: { href: "#" },
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass:
                                "category-filter__link category-filter__link--placeholder-4",
                              attrs: { href: "#" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "products__main" }, [
        _c("div", { staticClass: "products__top" }, [
          _c("h1", { staticClass: "products__title" }, [_vm._v("Proizvodi")]),
          _vm._v(" "),
          _c("div", { staticClass: "products__top-actions" }, [
            _c(
              "div",
              {
                staticClass: "products__filter-toggle",
                on: { click: _vm.toggleFilters },
              },
              [
                _c("div", { staticClass: "products__filter-toggle-inner" }, [
                  _c("span", { staticClass: "products__filter-toggle-title" }, [
                    _vm._v("Filtriraj"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "products__filter-toggle-icon" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "23",
                          height: "20",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M20.571 2.286H0V0h20.571v2.286zm-8.217 9.143H8v2.285h3.531a6.84 6.84 0 01.823-2.285zm4.789-5.715H3.429V8h13.714V5.714zm0 4.572v3.428h-3.429V16h3.429v3.429h2.286V16h3.428v-2.286H19.43v-3.428h-2.286z",
                            fill: "#000",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.closeSort,
                    expression: "closeSort",
                  },
                ],
                staticClass: "products__sort-toggle",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "products__sort-toggle-inner",
                    on: { click: _vm.toggleSort },
                  },
                  [
                    _c("span", { staticClass: "products__sort-toggle-title" }, [
                      _vm._v("Sortiraj"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "products__sort-toggle-icon",
                        class: "n" ? _vm.activePriceSort : "v",
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "22",
                              height: "19",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M17.6 19l-4.4-4.222h3.3V4.222h-3.3L17.6 0 22 4.222h-3.3v10.556H22L17.6 19zM0 16.889v-2.111h11v2.11H0zm0-6.333V8.444h7.7v2.112H0zm0-6.334v-2.11h4.4v2.11H0z",
                                fill: "#000",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.isSortVisible
                  ? _c(
                      "div",
                      { staticClass: "products__sort-toggle-dropdown" },
                      [
                        _c("ul", [
                          _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onSortItemClick("n")
                                },
                              },
                            },
                            [
                              _c("strong", [_vm._v("Cijena:")]),
                              _vm._v(" manja prema većoj"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onSortItemClick("v")
                                },
                              },
                            },
                            [
                              _c("strong", [_vm._v("Cijena:")]),
                              _vm._v(" veća prema manjoj"),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "products__items" },
          _vm._l(_vm.filteredProducts, function (product) {
            return _vm.isProductsLoaded
              ? _c("ProductItem", {
                  key: product.id,
                  attrs: {
                    product: product,
                    "user-username": _vm.userUsername,
                  },
                })
              : _c(
                  "article",
                  { staticClass: "mc-product mc-product--loading" },
                  [
                    _c("div", { staticClass: "mc-product__header" }, [
                      _c("img", {
                        staticClass: "mc-product__image",
                        attrs: {
                          src: "/media/cache/resolve/240x240/build/images/ms-no-image.jpg",
                          alt: "Loading product...",
                          loading: "lazy",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mc-product__body" }, [
                      _c("h3", { staticClass: "mc-product__title" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "price-login-alert price-login-alert--mc-product",
                        },
                        [_c("div", { staticClass: "price-login-alert__inner" })]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mc-product__footer" }, [
                      _c("button", { staticClass: "mc-add-to-cart" }),
                    ]),
                  ]
                )
          }),
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "products__footer" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }