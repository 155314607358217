import Vue from 'vue'
import VeeValidate from 'vee-validate'
import Vue2Filters from 'vue2-filters'
import Cookie from "./components/cookie/Cookie"
import MainSlider from "./components/main-slider/MainSlider";
import QuickLogin from "./components/quick-login/QuickLogin";
import DownloadInvoicePdf from "./components/download-invoice-pdf/DownloadInvoicePdf";
import DownloadInvoiceDocuments from "./components/download-invoice-documents/DownloadInvoiceDocuments";
import QuickCart from "./components/quick-cart/QuickCart";
import ProductsFilter from "./components/products-filter/ProductsFilter";
import ProductsCarousel from "./components/products-carousel/ProductsCarousel";
import PostsSlider from "./components/posts-carousel/PostsCarousel";
import Products from "./pages/Products";
import QuickSearch from "./components/quick-search/QuickSearch";
import ProductsQuickSearch from "./components/products-quick-search/ProductsQuickSearch";
import RelatedProducts from "./components/related-products/Related-products";
import Homepage from "./pages/Homepage";
import ContactMap from "./components/contact-map/ContactMap";
import ContactForm from "./components/contact-form/ContactForm";
import B2BForm from "./components/b2b-form/B2BForm";
import MyOrdersTable from "./components/my-orders-table/MyOrdersTable";
import ProductsCategory from "./pages/ProductsCategory";
import ProductsSubCategory from "./pages/ProductsSubCategory";

Vue.use(VeeValidate)
Vue.use(Vue2Filters)

export default new Vue({
    el: '#mastercentar',
    components: {
        'cookie': Cookie,
        'main-slider': MainSlider,
        'quick-login': QuickLogin,
        'quick-cart': QuickCart,
        'products-filter': ProductsFilter,
        'products-carousel': ProductsCarousel,
        'posts-carousel': PostsSlider,
        'products': Products,
        'products-category': ProductsCategory,
        'products-sub-category': ProductsSubCategory,
        'download-invoice-pdf': DownloadInvoicePdf,
        'download-invoice-documents': DownloadInvoiceDocuments,
        'quick-search': QuickSearch,
        'products-quick-search': ProductsQuickSearch,
        'homepage': Homepage,
        'related-products': RelatedProducts,
        'contact-map': ContactMap,
        'contact-form': ContactForm,
        'b2b-form': B2BForm,
        'my-orders-table': MyOrdersTable,
    }
});
