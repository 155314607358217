import '../styles/app.scss'
require('./_vue/vue');
import MobileMenu from "./_components/MobileMenu.component";
import DeliveryRadioSubmitForm from "./_components/DeliveryRadioSubmitForm.component";
import * as CookieConsent from "vanilla-cookieconsent";
import { gtag } from "ga-gtag";

const components = [
    {
        class: MobileMenu,
        selector: '.mc-mobile-ham'
    },
    {
        class: DeliveryRadioSubmitForm,
        selector: '.delivery_delivery_1'
    }
];

components.forEach(component =>{
    if (document.querySelector(component.selector) !== null) {
        document.querySelectorAll(component.selector).forEach(
            element => new component.class(element, component.options)
        );
    }
});


CookieConsent.run({
    disablePageInteraction: true,
    cookie: {
        name: 'cc_cookie',
        domain: location.hostname,
    },
    guiOptions: {
        consentModal: {
            layout: 'inline',
            position: 'bottom right',
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: true,
            flipButtons: false
        }
    },

    onConsent: ({cookie}) => {
        if (cookie.categories.length === 3 &&
            cookie.categories.includes('necessary') &&
            cookie.categories.includes('analytics') &&
            cookie.categories.includes('ads')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
                "personalization_storage": "granted",
                "ad_storage": "granted",
                "ad_user_data": "granted",
                "ad_personalization": "granted",
            });
        } else if(cookie.categories.length === 2 &&
            cookie.categories.includes('necessary') &&
            cookie.categories.includes('analytics')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
                "personalization_storage": "granted",
            });
        } else if(cookie.categories.length === 2 &&
            cookie.categories.includes('necessary') &&
            cookie.categories.includes('ads')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
                "ad_storage": "granted",
                "ad_user_data": "granted",
                "ad_personalization": "granted",
            });
        } else if(cookie.categories.length === 1 &&
            cookie.categories.includes('necessary')) {
            gtag('consent', 'update', {
                "security_storage": "granted",
                "functionality_storage": "granted",
                "analytics_storage": "granted",
            });
        }
    },

    onModalReady: ({modalName}) => {
        if (typeof gtag == "undefined") {
            function gtag(){dataLayer.push(arguments);}
        }
        gtag('consent', 'default', {
            "security_storage": "default",
            "functionality_storage": "default",
            "analytics_storage": "default",
            "personalization_storage": "default",
            "ad_storage": "default",
            "ad_user_data": "default",
            "ad_personalization": "default",
        });
    },
    categories: {
        necessary: {
            enabled: true,
            readOnly: true
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^_ga/,
                    },
                    {
                        name: '_gid',
                    }
                ]
            },

            services: {
                ga: {
                    label: 'Google Analytics',
                    onAccept: () => {},
                    onReject: () => {}
                },
            }
        },
        ads: {}
    },

    language: {
        default: 'en',
        translations: {
            en: {
                consentModal: {
                    title: 'Koristimo kolačiće',
                    description: `Ova stranica koristi kolačiće. Neki od ovih kolačića su neophodni, dok nam drugi pomažu da poboljšamo vaše iskustvo dajući uvid u to kako se stranica koristi. Za detaljnije informacije o kolačićima koje koristimo, pogledajte na stranici <a href="/pravila-privatnosti" target="_blank">Politika privatnosti.</a>`,
                    acceptAllBtn: 'Prihvatiti sve',
                    acceptNecessaryBtn: 'Odbaci sve',
                    showPreferencesBtn: 'Upravljanje individualnim preferencijama',
                },
                preferencesModal: {
                    title: 'Upravljajte postavkama kolačića',
                    acceptAllBtn: 'Prihvatiti sve',
                    acceptNecessaryBtn: 'Odbaci sve',
                    savePreferencesBtn: 'Prihvati trenutni odabir',
                    closeIconLabel: 'Zatvori modal',
                    serviceCounterLabel: 'Usluga|Usluge',
                    sections: [
                        {
                            title: 'Vaš izbor privatnosti',
                            description: `U ovoj ploči možete izraziti neke preferencije vezane uz obradu vaših osobnih podataka. Možete pregledati i promijeniti izražene izbore u bilo kojem trenutku ponovnim pojavljivanjem ove ploče putem navedene veze. Kako biste odbili svoj pristanak na određene aktivnosti obrade opisane u nastavku, isključite preklopnike ili upotrijebite gumb "Odbaci sve" i potvrdite da želite spremiti svoje odabire.`,
                        },
                        {
                            title: 'Neophodni',
                            description: 'Ovi su kolačići neophodni za pravilno funkcioniranje web stranice i ne mogu se onemogućiti.',
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Analitika',
                            description: 'Ovi kolačići prikupljaju informacije o tome kako koristite našu web stranicu. Svi podaci su anonimizirani i ne mogu se koristiti za vašu identifikaciju.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Cookie table',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description'
                                },
                                body: [
                                    {
                                        name: '_ga',
                                        domain: location.hostname,
                                        desc: '',
                                    },
                                    {
                                        name: '_gid',
                                        domain: location.hostname,
                                        desc: '',
                                    }
                                ]
                            }
                        },
                        {
                            title: 'Marketing',
                            description: 'Ovi se kolačići koriste kako bi oglasne poruke bile relevantnije za vas i vaše interese. Namjera je prikazati oglase koji su relevantni i privlačni za pojedinačnog korisnika, a time i vrijedniji za izdavače i oglašivače treće strane.',
                            linkedCategory: 'ads',
                        },
                        {
                            title: 'Više informacija',
                            description: 'Za sve upite u vezi s mojim pravilima o kolačićima i vašim izborima, molimo <a href="/kontakt">kontaktirajte nas</a>'
                        }
                    ]
                }
            }
        }
    }
});
