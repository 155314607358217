<template>
  <div v-click-outside="close" class="quick-login">
    <div @click="toggle" class="quick-login__header">
      <span v-if="!userUsername" class="quick-login__text">Prijavi se</span>
      <span v-else class="quick-login__text">{{ nickName }}</span>
      <span class="quick-login__link-icon">
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 0a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4zm0 7c2.67 0 8 1.33 8 4v3H0v-3c0-2.67 5.33-4 8-4zm0 1.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V13c0-.64-3.13-2.1-6.1-2.1z"/></svg>
      </span>
    </div>
    <div v-if="isOpen" class="quick-login__body">
      <form v-if="!userUsername" method="post" action="/prijava">
        <div class="quick-login__form-group">
          <label class="quick-login__form-label">Korisničko ime ili e-mail:</label>
          <input type="email" name="email" id="inputEmail" class="quick-login__form-input" required autofocus>
        </div>
        <div class="quick-login__form-group">
          <label class="quick-login__form-label">Lozinka <a class="quick-login__form-label-link" href="/reset-password">(Zaboravljena lozinka?)</a></label>
          <input type="password" name="password" id="inputPassword" class="quick-login__form-input" required>
        </div>
        <input type="hidden" name="_csrf_token"
               :value="csrfToken"
        >
        <button class="quick-login__button" type="submit">Prijavi se</button>
        <span class="quick-login__register">
          Nemaš korisnički račun? <a class="quick-login__link" href="/registracija">Registruj se</a>
        </span>
      </form>
      <div v-else>
        <p>Prijavljeni ste kao: <strong>{{ userUsername }}</strong></p>
        <a class="quick-login__button" href="/moje-narudzbe">Moje narudžbe</a>
        <a class="quick-login__button2" href="/odjava">Odjava</a>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'QuickLogin',
  props: {
    csrfToken: {
      type: String,
      required: true
    },
    userUsername: {
      type: String,
      required: true
    },
    nickName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    close() {
      this.isOpen = false
    }
  },
  directives: {
    ClickOutside
  },
}
</script>
