<template>
  <div class="mc-main-slider">
    <div class="mc-main-slider__inner">
      <swiper
        ref="postsSlider"
        :options="swiperOptions">
        <swiper-slide v-for="(slide, key) in entities" :key="key">
          <div class="mc-main-slider__slide">
            <div class="mc-main-slider__meta" data-swiper-parallax="-200">
              <span v-if="slide.brand" class="mc-main-slider__brand">{{ slide.brand.name }}</span>
              <h2 class="mc-main-slider__title" data-swiper-parallax="-300">{{ slide.name }}</h2>
              <p v-if="slide.description" class="mc-main-slider__description"  data-swiper-parallax="-200">{{ truncate(slide.description, 120) }}</p>
              <div v-if="userUsername" class="mc-main-slider__price-wrapper" data-swiper-parallax="-220">
                <span class="mc-main-slider__price">{{ currencyFormat(slide.price) }}</span>
                <span class="mc-main-slider__currency">eur</span>
              </div>
              <div v-else class="price-login-alert price-login-alert--main-slider" data-swiper-parallax="-220">
                <div class="price-login-alert__inner">
                <span class="price-login-alert__icon">
                  <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.3 4.9h1.4V3.5H6.3v1.4zm.7 7.7A5.607 5.607 0 011.4 7c0-3.087 2.513-5.6 5.6-5.6 3.087 0 5.6 2.513 5.6 5.6 0 3.087-2.513 5.6-5.6 5.6zM7 0a7 7 0 100 14A7 7 0 007 0zm-.7 10.5h1.4V6.3H6.3v4.2z" fill="#DD181F"></path></svg>
                </span>
                  <span class="price-login-alert__text">Za prikaz cijene, potrebna je prijava.</span>
                </div>
              </div>
              <a :href="'/proizvodi/' + slide.category.root.slug + '/' + slide.category.slug + '/' + slide.slug" class="mc-add-to-cart mc-add-to-cart--border mc-add-to-cart--w216" data-swiper-parallax="-120">Saznaj više</a>
            </div>
            <div class="mc-main-slider__image-wrapper" data-swiper-parallax="-100">
              <picture>
                <source media="(min-width:650px)" :srcset="slide.featuredImage.src | variation('445x445')">
                <source media="(min-width:465px)" :srcset="slide.featuredImage.src | variation('445x260')">
                <img class="mc-main-slider__image" :src="slide.featuredImage.src | variation('445x260')" :alt="slide.name" loading="lazy">
              </picture>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'MainSlider',
  filters: {
    variation: function(src, size) {
      if (!size) return  '/uploads/' + src
      if (!src) return '/media/cache/resolve/' + size + '/build/images/ms-no-image.jpg'
      return '/media/cache/resolve/' + size + src
    }
  },
  props: ['title', 'subtitle', 'entities', 'userUsername'],
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOptions: {
        parallax: true,
        autoplay: {
          delay: 5000,
        },
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 30,
        effect: 'slide',
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.postsSlider.$swiper
    }
  },
  methods: {
    truncate(text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    },
    currencyFormat(num) {
      return parseFloat(num)
          .toFixed(2)
          .replace('.', ',')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
  }
};
</script>
