<template>
  <div style="height: 500px; width: 100%">
    <l-map
      v-if="showMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 80%"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-marker :lat-lng="withPopup" :icon="icon">
        <l-popup>
          <span>Master MN</span>
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon} from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';

export default {
  name: 'ContactMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon
  },
  data() {
    return {
      zoom: 15,
      center: latLng(42.4521407, 19.2348871),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(42.4521407, 19.2348871),
      currentZoom: 11.5,
      currentCenter: latLng(42.4521407, 19.2348871),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      icon: L.icon({
        iconUrl: '/build/images/marker-icon.png',
        iconSize: [25, 41],
      }),
      showMap: true
    }
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    }
  }
}
</script>
