var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "500px", width: "100%" } },
    [
      _vm.showMap
        ? _c(
            "l-map",
            {
              staticStyle: { height: "80%" },
              attrs: {
                zoom: _vm.zoom,
                center: _vm.center,
                options: _vm.mapOptions,
              },
              on: {
                "update:center": _vm.centerUpdate,
                "update:zoom": _vm.zoomUpdate,
              },
            },
            [
              _c("l-tile-layer", {
                attrs: { url: _vm.url, attribution: _vm.attribution },
              }),
              _vm._v(" "),
              _c(
                "l-marker",
                { attrs: { "lat-lng": _vm.withPopup, icon: _vm.icon } },
                [_c("l-popup", [_c("span", [_vm._v("Master MN")])])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }