<template>
  <div v-if="isShow" class="order-modal">
    <div class="order-modal__inner">
      <h1>Order Modal</h1>
      <h2>{{ orderId }}</h2>
      <div>
        <ul>
          <li v-for="(item, key) in items" :key="key">
            <div><strong>Name: </strong>{{ item.product.name }}</div>
            <div><strong>Quantity: </strong>{{ item.quantity }}</div>
            <div><strong>Price: </strong>{{ currencyFormat(item.product.price) }} €</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";

export default {
  name: 'OrderModal',
  filters: {
    formatDate: (value) => {
      if (value) return format(new Date(value), 'dd.MM.yyyy.')
    }
  },
  props: ['isShow', 'orderId', 'items'],
  data() {
    return {
      order: null,
    }
  },
  mounted() {},
  methods: {
    currencyFormat(num) {
      return parseFloat(num)
          .toFixed(2)
          .replace('.', ',')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
  }
}
</script>