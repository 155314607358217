var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ms-alert" }, [
    _c("div", { staticClass: "ms-alert__title" }, [
      _vm._v("Preuzmi sve artikle u obliku PDF-a."),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "ms-alert__content" }, [
      _vm._v(
        "Nudimo ti mogućnost preuzimanja košarice u obliku PDF-a. Košaricu je moguće preuzeti s popustom i bez popusta."
      ),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "ms-alert__text-block" }, [_vm._v("Preuzmi:")]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "mc-button mc-button--cart-download",
        on: {
          click: function ($event) {
            return _vm.downloadCartInvoice("with_discount")
          },
        },
      },
      [_vm._v("S popustom")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "mc-button mc-button--cart-download",
        on: {
          click: function ($event) {
            return _vm.downloadCartInvoice("without_discount")
          },
        },
      },
      [_vm._v("Bez popusta")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "mc-button mc-button--cart-download",
        on: {
          click: function ($event) {
            return _vm.downloadCartInvoice("add_discount")
          },
        },
      },
      [_vm._v("Dodaj popust")]
    ),
    _vm._v(" "),
    _vm.isCustomDiscountFieldVisible
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.customDiscountField,
              expression: "customDiscountField",
            },
          ],
          attrs: { type: "number", placeholder: "Unesite željeni popust" },
          domProps: { value: _vm.customDiscountField },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.customDiscountField = $event.target.value
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.isCustomDiscountFieldVisible
      ? _c(
          "button",
          {
            staticClass: "mc-button mc-button--cart-download",
            on: {
              click: function ($event) {
                return _vm.downloadCartInvoice("add_discount_2")
              },
            },
          },
          [_vm._v("Preuzmi")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }