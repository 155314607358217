<template>
  <form class="register__form">
    <div class="ms-form-group">
      <label class="ms-form-group__label">Ime kompanije: *</label>
      <input
        v-validate="'required'"
        v-model="companyName"
        name="companyName"
        class="ms-form-group__input"
        type="text"
        placeholder="Unesite ime kompanije"
        :class="{'ms-form-group__input--invalid': errors.first('companyName')}"
      >
    </div>
    <div class="ms-form-group">
      <label class="ms-form-group__label">PIB: *</label>
      <input
        v-validate="'required'"
        v-model="oib"
        name="oib"
        class="ms-form-group__input"
        type="number"
        placeholder="Unesite PIB kompanije"
        :class="{'ms-form-group__input--invalid': errors.first('oib')}"
      >
    </div>
    <div class="ms-form-group">
      <label class="ms-form-group__label">Telefon: *</label>
      <input
        v-validate="'required'"
        v-model="phone"
        name="phone"
        class="ms-form-group__input"
        type="text"
        placeholder="Unesite telefon kupca"
        :class="{'ms-form-group__input--invalid': errors.first('phone')}"
      >
    </div>
    <div class="ms-form-group">
      <label class="ms-form-group__label">E-mail adresa: *</label>
      <input
        v-validate="'required'"
        v-model="email"
        name="email"
        class="ms-form-group__input"
        type="email"
        placeholder="Unesite e-mail adresu"
        :class="{'ms-form-group__input--invalid': errors.first('email')}"
      >
    </div>
    <button @click.prevent="submit" class="mc-button mc-button--register">Pošalji</button>
    <div v-if="showSuccessMessage" class="msg">
      <span>Loading...</span>
    </div>
  </form>
</template>

<script>
import ApiService from "../../services/api.service"

export default {
  name: 'B2BForm',
  data() {
    return {
      companyName: null,
      oib: null,
      phone: null,
      email: null,
      isVerified: false,
      showSuccessMessage: null
    }
  },
  methods: {
    submit() {
      this.showSuccessMessage = true
      ApiService.post('/api/v1/companies', {
        name: this.companyName,
        oib: this.oib,
        phone: this.phone,
        email: this.email,
        address: '',
        city: '',
        postalCode: '',
        deliveryAddress: '',
        paymentType: '',
        isVerified: this.isVerified
      }).then(response => {
        if (response.status === 201) {
          this.showSuccessMessage = false
          window.location.href = '/uspjesna-registracija'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.msg {
  margin-top: 30px;
}
</style>
