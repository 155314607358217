<template>
  <div class="ms-alert">
    <div class="ms-alert__title">Preuzmi sve artikle u obliku PDF-a.</div>
    <p class="ms-alert__content">Nudimo ti mogućnost preuzimanja košarice u obliku PDF-a. Košaricu je moguće preuzeti s popustom i bez popusta.</p>
    <span class="ms-alert__text-block">Preuzmi:</span>
    <button @click="downloadCartInvoice('with_discount')" class="mc-button mc-button--cart-download">S popustom</button>
    <button @click="downloadCartInvoice('without_discount')" class="mc-button mc-button--cart-download">Bez popusta</button>
    <button @click="downloadCartInvoice('add_discount')" class="mc-button mc-button--cart-download">Dodaj popust</button>
    <input v-if="isCustomDiscountFieldVisible" type="number" v-model="customDiscountField" placeholder="Unesite željeni popust">
    <button v-if="isCustomDiscountFieldVisible" @click="downloadCartInvoice('add_discount_2')" class="mc-button mc-button--cart-download">Preuzmi</button>
  </div>
</template>

<script>
export default {
  name: 'DownloadInvoicePdf',
  props: ['cartId', 'userId', 'deliveryPrice'],
  data() {
    return {
      data: 'DownloadInvoicePdf',
      customDiscountField: null,
      isCustomDiscountFieldVisible: false
    }
  },
  methods: {
    downloadCartInvoice(type) {
      if (type === 'with_discount') {
        this.isCustomDiscountFieldVisible = false

        let d = 0
        if (this.deliveryPrice) {
          d = 1
        }

        window
            .open(
                `api/v1/exports/pdf/cart-export/${this.cartId}/${this.userId}/-1/${d}`,
                '_blank'
            )
            .focus()
      }
      if (type === 'without_discount') {
        this.isCustomDiscountFieldVisible = false

        window
          .open(
              `api/v1/exports/pdf/cart-export/${this.cartId}/${this.userId}/0/-1`,
              '_blank'
          )
          .focus()
      }
      if (type === 'add_discount') {
        this.isCustomDiscountFieldVisible = !this.isCustomDiscountFieldVisible
      }
      if (type === 'add_discount_2') {
        window
          .open(
              `api/v1/exports/pdf/cart-export/${this.cartId}/${this.userId}/${this.customDiscountField}/-1`,
              '_blank'
          )
            .focus()
      }
    }
  }
}
</script>
