var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mc-products-quick-search" }, [
    _c(
      "div",
      { staticClass: "mc-products-quick-search__inner" },
      [
        _c("vue-autosuggest", {
          ref: "autocomplete",
          attrs: {
            suggestions: _vm.suggestions,
            "input-props": _vm.inputProps,
            "section-configs": _vm.sectionConfigs,
            "get-suggestion-value": _vm.getSuggestionValue,
          },
          on: { input: _vm.fetchResults },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ suggestion }) {
                return [
                  suggestion.name === "products"
                    ? _c(
                        "div",
                        { staticClass: "autosuggest__results-item-wrapper" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "autosuggest__results-item-name",
                              attrs: {
                                target: "_blank",
                                href: suggestion.item.url,
                              },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.highlight(
                                      suggestion.item.name,
                                      _vm.query
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _vm.userUsername
                                ? _c("span", {
                                    domProps: { innerHTML: _vm._s(" - ") },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.userUsername
                                ? _c("span", {
                                    staticClass:
                                      "autosuggest__results-item-price",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.currencyFormat(
                                          suggestion.item.price
                                        )
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.userUsername
                                ? _c("span", {
                                    staticClass:
                                      "autosuggest__results-item-price-currency",
                                    domProps: { innerHTML: _vm._s("EUR") },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass:
                              "autosuggest__results-item-description",
                            domProps: {
                              innerHTML: _vm._s(
                                suggestion.item.shortDescription
                              ),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  suggestion.name === "posts"
                    ? _c(
                        "div",
                        { staticClass: "autosuggest__results-item-wrapper" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "autosuggest__results-item-content",
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_blank",
                                    href: suggestion.item.url,
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$options.filters.highlight(
                                          suggestion.item.title,
                                          _vm.query
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.query,
            callback: function ($$v) {
              _vm.query = $$v
            },
            expression: "query",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mc-products-quick-search__icon" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "30",
                height: "30",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M5.656 2.145A12.727 12.727 0 0112.727 0v1.176V0a12.728 12.728 0 0112.727 12.727A12.727 12.727 0 115.657 2.145zm7.071.208a10.375 10.375 0 0110.375 10.374M12.727 2.353a10.374 10.374 0 1010.375 10.374",
                  fill: "#DD181F",
                },
              }),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M20.33 20.33a1.129 1.129 0 011.597 0l7.742 7.742a1.13 1.13 0 01-1.596 1.597l-7.742-7.742a1.13 1.13 0 010-1.596z",
                  fill: "#DD181F",
                },
              }),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }